<template>
  <el-dialog title="小程序预览" :visible.sync="isOpenWatch" @close="handleClose">
    <div class="watch_warp" v-loading="!watchObj" >
        <img src="@/assets/img/background_phone.png" style="width: 375px"  alt=""/>

        <div :class="['watch_content',{'watch_warp_hidden':show||showSite||showNotice}]" v-if="watchObj&&isOpenWatch ">
           <el-carousel v-if="watchObj.mainImageUrl" height="300px" direction="horizontal" autoplay>
              <el-carousel-item v-for="(src,i) in watchObj.mainImageUrl?.split(';')" :key="src">
                <div class="image_warp">
                  <img :src="src" style="width: 300px;height: 300px" />
                  <div class="image_bg" :style="{'background': `url(${watchObj.decorateUrl}) no-repeat center center / 100% 100%`}"></div>
                </div>
                <div class="watch_num">{{i+1}}/{{watchObj.mainImageUrl?.split(';').length}}</div>
              </el-carousel-item>
            </el-carousel>
           <div v-else class="mainImageUrl">商品主图,请上传</div>
          <div class="introduce">
            <div class="introduce_header">
              <div >￥<span class="price">{{watchObj?.minPrice||'0.00'}}</span></div>
            </div>
            <div class="introduce_middle">
              <span class="tag" v-for="(item,i) in watchObj?.tag" :key="i">{{item}}</span>
              <span class="tag" v-if="watchObj?.tag.length===0">服务标签</span>
              <span class="goodsName">{{watchObj?.goodsName||'服务名称'}}</span>
            </div>
            <div class="introduce_footer" >
              <span class="tip">{{watchObj?.tip||'推广Tip'}}</span>
            </div>
          </div>

          <div class="siteChooseTime">
            <div class="site">
              <div class="lable">可用区域：</div>
              <div class="value" v-if="availableSite" @click="showSite=true">{{availableSite||'适用城市区域'}}</div>
              <div class="value" v-else>{{availableSite||'适用城市区域'}}</div>
              <div class="icon" v-if="availableSite" @click="showSite=true"><i class="el-icon-arrow-right"></i></div>
              <div class="icon" v-else><i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="choose">
              <div class="lable">规格：</div>
              <div class="value" @click="show=true">{{cellValue||'请填写服务规格、价格'}}</div>
              <div class="icon" @click="show=true"><i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="time">
              <div class="lable">购买须知：</div>
                <div class="value" v-if="watchObj.unavailableTimeType.length>0" @click="showNotice =true" >{{unavailableTime(watchObj.unavailableTimeType,watchObj.unavailableTimeStr)||'请选择不可使用日期'}}</div>
                <div class="value" v-else >{{unavailableTime(watchObj.unavailableTimeType,watchObj.unavailableTimeStr)||'请选择不可使用日期'}}</div>
                <div class="icon" v-if="watchObj.unavailableTimeType.length>0" @click="showNotice =true"><i class="el-icon-arrow-right"></i></div>
                <div class="icon" v-else ><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>

          <div class="company">
          <!--            <el-image :src="goodsInfo.logo" mode=""></el-image>-->
            <div>
              <div class="abbreviation">
                公司全称
              </div>
              <div class="logotype">
                <div class="business">
                  <ch-icon name="business" style="width: 13px;height:15px;margin-right: 5px;" />
                  <span class="text">平台认证</span>
                </div><!--v-if="!!goodsInfo.wuyeAuth"-->
                <div class="wuye" >
                  <ch-icon name="business" style="width: 13px;height:15px;margin-right: 5px;" />
                  <span class="text">物业认证</span>
                </div>
              </div>
            </div>
          </div>

          <div class="details">
            <Editor
                v-if="watchObj.detail!=='<p><br></p>'"
                :editorId="editorId"
                :defaultConfig="{ readOnly: true }"
                :defaultHtml="watchObj.detail"
                mode="default"
            />
            <div v-else class="details">请填写商品描述</div>
          </div>
          <div class="serveExplain">
            <div class="title">服务说明</div>
            <el-image v-if="watchObj.serveExplain" width="100%" fit='cover' :src="watchObj.serveExplain" />
            <div v-else style="margin-left: 10px;font-weight: normal">请上传服务说明</div>
          </div>
          <div class="chargeExplain">
            <div class="title">收费说明</div>
            <el-image v-if="watchObj.chargeExplain" width="100%" fit='cover' :src="watchObj.chargeExplain" />
            <div v-else style="margin-left: 10px;font-weight: normal">请上传收费说明</div>
          </div>
          <div class="tip">
            <div class="title">温馨提示</div>
            <div class="text">点击“立即服务”按钮后即默认您已阅读和接受上述产品页面信息。</div>
          </div>

          <div class="mask" v-show="show||showSite||showNotice" @click="show=false;showSite=false;showNotice=false"></div>

          <div class="show_popup" v-show="show">

            <div class="show_head">
              <div class="image_warp_head" v-if="watchObj.coverUrl">
                <el-image style="width: 115px;height: 115px;border-radius: 8px" :src="watchObj.coverUrl"/>
                <div class="image_bg" :style="{'background': `url(${watchObj.decorateUrl}) no-repeat center center / 100% 100%`}"></div>
              </div>
              <div v-else style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;background: #f8f8f8;font-size: 12px;width: 115px;height: 115px;padding: 10px">请上传商品封面图</div>
              <div class="head_content">
                <div class="name">服务名称：{{info.specName}}</div>
                <div class="detail">规格描述：{{info.specDetail}}</div>
                <div class="time" v-if="watchObj.templateId===1">服务时长：{{info.duration}}小时</div>
                <div class="num">服务次数：{{info.number}}次</div>
<!--                <div class="price">￥{{info.price}}</div>-->
              </div>
            </div>

            <div class="specify_name">
              <div class="specName">规格名称</div>
              <div :class="['specify_info', {active:activeName===index}]" @click.stop="getInfo(index)"
                   v-for="(item,index) in specNameList" :key="index">
                <div class="info" v-if="activeName===index">
                  <div  class="info_name" >
                    <div class="name" style="color: #3D7FFF;">{{item.specName}}</div>
                    <div v-if="watchObj.templateId===1" class="name" style="color: #3D7FFF;">({{info.duration+'小时x'+ info.number+'次'}})</div>
                    <i v-if="item.serveDetailList.length" class="el-icon-warning-outline"  style="padding-left: 5px;color: #BFBFBF" @click.stop="clickTipShow(item)"></i>
                  </div>
                  <div class="strikethrough">
                    ￥<span>{{info.originalPrice}}</span>
                  </div>
                  <div class="price">￥<span>{{info.price}}</span></div>
                </div>
                <div class="info" v-else>
                  <div  class="info_name">
                    <div class="name">{{item.specName}}</div>
                    <div v-if="watchObj.templateId===1" class="name" style="color: #3D7FFF;">({{info.duration+'小时x'+ info.number+'次'}})</div>
                    <i v-if="item.serveDetailList.length" class="el-icon-warning-outline"  style="padding-left: 5px;color: #BFBFBF" @click.stop="clickTipShow(item)"></i>
                  </div>
                  <div class="strikethrough">
                    ￥<span>{{item.originalPrice}}</span>
                  </div>
                  <div class="price">￥<span>{{item.price}}</span></div>
                </div>
              </div>
              <div class="specify_info active" v-if="specNameList.length===0">
                <div class="info">
                  商品名称
                </div>
              </div>
            </div>
            <div class="specify_time" v-if="watchObj.templateId===1">
              <div class="title">服务时长</div>
              <div class="time_box">
                <div :class="['time',{active: activeDur===index}]" @click="getDuration(index)"
                     v-for="(item,index) in duration" :key="index">{{item}}小时</div>
                <div class="active time" v-if="duration.length===0">小时</div>
              </div>
              <div class="specify_tip" v-if="info.stipulate">{{info.duration}}小时适合房屋面积{{info.stipulate}}</div>
            </div>
            <div class="specify_num">
              <div class="title">服务次数</div>
              <div class="time_box">
                <div :class="['time',{active: activeNum===index}]" @click="getNum(index)"
                     v-for="(num,index) in number" :key="num">{{num}}次</div>
                <div class="active time" v-if="number.length===0">次数</div>
              </div>
            </div>
            <div class="show_foot" @click="show=false">
              确定
            </div>
          </div>

          <div class="show_site" v-show="showSite&&availableSite">
            <div class="title">购买须知</div>
            <div class="site">
              <div class="label">适用城市区域：</div>
              <div class="value">{{availableSite}}</div>
            </div>
            <div class="">
              <ch-button class="ch_button" type="ok"  @click="showSite = false">我知道了</ch-button>
            </div>
          </div>

          <div class="show_notice" v-show="showNotice">
            <div class="title">购买须知</div>
            <div class="unavailable_time">
              <div class="label">不可用时间：</div>
              <div class="value">{{unavailableTime(watchObj.unavailableTimeType,watchObj.unavailableTimeStr)}}</div>
            </div>
            <div>
              <ch-button class="ch_button" type="ok"  @click="showNotice = false">我知道了</ch-button>
            </div>
          </div>

          <div class="show_tip" v-show="showTip">
            <div class="title">服务明细</div>
            <div class="tip_list">
              <div class="tip_item" v-for="(tip,i) in tips" :key="i">
                {{tip}}
              </div>
            </div>
            <ch-button class="ch_button" type="ok"  @click="onClickTipHide">我知道了</ch-button>
          </div>
      </div>
    </div>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="isOpenWatch=false">取消</ch-button>
    </div>
  </el-dialog>
</template>


<script>
import { Editor } from '@wangeditor/editor-for-vue'
import _ from 'lodash'
import dayjs from "dayjs";
export default {
  name: "commodityWatch",
  components:{
    Editor
  },
  data() {
    return {
      isOpenWatch: false,
      availableSite:'',
      editorId: 'commodityWatch',
      show:false,
      showSite:false,
      showNotice:false,
      showTip: false,
      info:{},
      activeName:0,
      activeDur:0,
      activeNum:0,
      specNameList:[],
      spec:[],
      duration:[],
      number:[],
      cellValue:'',
      watchObj: null,
      tips: [],
    };
  },
  methods: {
    async openAddWatch(watchObj,id){
      if(id){
        this.getRowInfo(id)
      }else {
        this.watchObj = watchObj;
        this.init()
      }
    },
    init(){
      this.isOpenWatch = true;
      this.availableSite = this.watchObj.hmGoodsSiteSet&& this.watchObj.hmGoodsSiteSet?.map(item=>item.district).join('、')
      this.spec = this.watchObj.hmGoodsSpecVoList?.filter(item=>{
        return  item.once||item.twice||item.three
      }).map(item=>{
        let arr = []
        if(item.once){
          arr.push({
            duration:item.duration,
            number:1,
            price:item.once,
            originalPrice:item.onceOriginalCost,
            specName:item.specName,
            specDetail:item.specDetail,
            serveDetailList:item.serveDetailList,
            stipulate: item.stipulate.replace('<','')
          })
        }
        if(item.twice){
          arr.push({
            duration:item.duration,
            number:2,
            price:item.twice,
            originalPrice:item.twiceOriginalCost,
            specName:item.specName,
            specDetail:item.specDetail,
            serveDetailList:item.serveDetailList,
            stipulate: item.stipulate.replace('<','')
          })
        }
        if(item.three){
          arr.push({
            duration:item.duration,
            number:3,
            price:item.three,
            originalPrice:item.threeOriginalCost,
            specName:item.specName,
            specDetail:item.specDetail,
            serveDetailList:item.serveDetailList,
            stipulate: item.stipulate.replace('<','')
          })
        }
        return arr
      }).flat() || []

      if(this.spec.length===0) return

      this.specNameList = _.uniqBy(this.spec, 'specName')
      if(this.watchObj.templateId===1){
        this.duration = [...new Set(this.spec.map(item => item.duration))].sort()
      }
      this.number = this.getNumber()
      this.getInfo(0)
    },
    unavailableTime(type,data){
      if(type?.length===0){
        return  ''
      }
      let str = ''
      if(type.includes(0)){
        str += '无'
      }
      if(type.includes(1)){
        str += '工作日(周一至周五);'
      }
      if(type.includes(2)){
        str += '周末(周六和周日);'
      }
      if(type.includes(3)){
        str += data+';'
      }
      return str
    },
    getInfo(index){
      this.activeName = index
      this.number = this.getNumber()
      let name = this.specNameList[this.activeName].specName
      if(this.watchObj.templateId===1){
        const specFilter = this.spec.filter(item => item.specName===name)
        this.duration = [...new Set(specFilter.map(item => item.duration))].sort()
        this.info = this.spec.filter(item => item.specName===name&&item.number===this.number[this.activeNum]
            &&item.duration===this.duration[this.activeDur])[0]
        this.cellValue = `${this.info.specName};时长:${this.info.duration}小时*${this.info.number}次`
        return
      }
      this.info = this.spec.filter(item => item.specName===name&&item.number===this.number[this.activeNum])[0]
      this.cellValue = `${this.info.specName};${this.info.number}次`
    },
    // 详情
    getRowInfo(id){
     this.$curl.post('/hm/goods/info/'+id).then(res=>{
        this.templateId = res.data.templateId
        const infoModalForm = res.data
        const specifyModalForm = {}
        delete infoModalForm.status
        const shopModalForm = res.data.hmGoodsDetail
        infoModalForm.classifyIds = [infoModalForm.firstClassifyId,infoModalForm.classifyId]
        if(res.data.payType){
          this.payType = res.data.payType.split(',')
        }
        const unavailableTimeType = res.data.hmGoodsDetail.unavailableTimeType.split(',').map(Number).filter(item=>item)
        infoModalForm.city =  JSON.parse(res.data.hmGoodsDetail.goodsSiteStr)
        const hmGoodsSiteSet = res.data.hmGoodsSiteSet
        const dates = res.data.hmGoodsDetail.unavailableTimeStr.split(',')
        // 服务标签
        const selectTags = res.data.tag && res.data.tag?.split(',') || []
        specifyModalForm.specId = res.data.specId
        specifyModalForm.hmGoodsSiteSet = res.data?.hmGoodsSiteSet
        specifyModalForm.overtimePay = res.data.overtimePay

        this.watchObj = {
          ...specifyModalForm, // 规格类型 规格名称 服务时长 规格描述 服务明细
          ...infoModalForm, // 服务名称 推广Tip 商品封面图
          ...shopModalForm, // 主图 服务说明 收费说明
          tag:selectTags,  // 服务标签 specDetail
          hmGoodsSiteSet:hmGoodsSiteSet,  // 适用城市
          unavailableTimeType:unavailableTimeType, // 不可用 类型
          unavailableTimeStr: dates?.map(item => dayjs(item).format('YYYY-MM-DD')).join(',') || '', // 不可以日期
        }
        this.init()
      })
    },

    getDuration(index){
      this.activeDur = index
      this.number = this.getNumber()
      if(this.number.length===1|| this.number.length<this.activeNum+1){
        this.activeNum = 0
      }
      let name = this.specNameList[this.activeName].specName
      if(this.watchObj.specId === 1){
        this.info = this.spec.filter(item => item.specName===name&&item.number===this.number[this.activeNum]
            &&item.duration===this.duration[this.activeDur])[0]
        return
      }
      this.info = this.spec.filter(item => item.specName===name&&item.number===this.number[this.activeNum])[0]
    },

    getNum(index){
      this.activeNum = index
      const number = this.number[this.activeNum]
      this.specNameList = _.uniqBy(this.spec.filter(item=> item.number == number), 'specName')
      console.log(this.specNameList,'this.specNameList')
      let name = this.specNameList[this.activeName].specName
      if(this.watchObj.specId ===1){
        this.info = this.spec.filter(item => item.specName===name&&item.number===number
            &&item.duration===this.duration[this.activeDur])[0]
        return
      }
      this.info = this.spec.filter(item => item.specName===name&&item.number===number)[0]
    },

    getNumber(){
      let arr = []
      if(this.watchObj.templateId ===1){
        this.spec.forEach((item)=> {
          if(item.duration===this.duration[this.activeDur]){
            arr.push(item.number)
          }
        })
      }else{
        this.spec.forEach(item => {
          if(item.specName===this.specNameList[this.activeName].specName){
            arr.push(item.number)
          }
        })
      }
      return arr
    },
    clickTipShow(item){
      this.showTip = true
      this.tips = item.serveDetailList
    },
    onClickTipHide() {
      this.showTip = false
      this.tips = []
    },
    handleClose(){
      this.isOpenWatch = false
      this.show = false
      this.showSite = false
      this.showNotice = false
      this.showTip = false
      this.info = {}
      this.activeName = 0
      this.activeDur = 0
      this.activeNum = 0
      this.specNameList = []
      this.spec = []
      this.duration = []
      this.number = []
      this.watchObj = {}
    }
  }
}
</script>


<style scoped lang="scss">
::-webkit-scrollbar{
  display: none;
}
.watch_warp{
  display: flex;
  justify-content: center;
  position: relative;
  width: 375px;
  .watch_warp_hidden{
    overflow: hidden !important;
  }
  .watch_content{
    background: #f8f8f8;
    position: absolute;
    top: 40px;
    width: 330px;
    height: calc(100% - 80px);
    overflow: auto;
    .image_warp {
      position: relative;
      margin: 0 15px;
      .image_bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
    .image_warp_head {
      position: relative;
      .image_bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .watch_num{
      position: absolute;
      bottom: 15px;
      right: 15px;
      color: #ffffff;
      width: 30px;
      height: 18px;
      font-size: 10px;
      background: rgba(0,0,0,0.55);
      border-radius: 10px;
      text-align: center;
      padding: 2px 2px 4px 2px;
      z-index: 100;
    }

    .introduce{
      padding: 10px 10px;
      margin-bottom: 10px;
      background-color: #ffffff;
      .introduce_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color:#EB0A0A;
        .price{
          font-size: 24px;
          font-weight: bold;
        }
        .single{
          font-size: 14rpx;
          color: #9D9D99;
        }
      }
      .introduce_middle{
        padding: 10px 0;
        .tag{
          font-size: 10px;
          padding: 2px 8px;
          background: #3D7FFF;
          color: #ffffff;
          border-radius: 10px;
          line-height: 16px;
          margin-right: 5px;
        }
        .goodsName{ //换行
          white-space: pre-wrap;
          word-break: break-all;

        }
      }
      .introduce_footer{
        .tip{
          font-size: 10px;
          color: #6D6D6D;
          padding: 2px 8px;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 10px;
        }
      }
    }

    .siteChooseTime{
      background: #fff;
      .site,
      .choose,
      .time{
        font-size: 12px;
        display: flex;
        padding: 5px 0;
        margin: 0 10px;
        border-bottom: 1px solid rgba(238, 238, 238, 0.5);
        .lable{
          width: 60px;
          text-align: right;
          color: #333333;
        }
        .value{
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #999999;
          cursor: pointer;
        }
        .icon{
          width: 10px;
          cursor: pointer;
        }
      }
      .time{
        border-bottom: none;
      }
    }

    .company{
      background-color: #fff;
      display: flex;
      margin: 10px 0;
      padding: 15px;
      image{
        width: 45px;
        height: 45px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .abbreviation	{
        font-size: 14px;
        height: 20px;
        font-weight: bold;
      }
      .logotype{
        display: flex;
        margin-top: 4px;
        .business,
        .wuye{
          background-color: #eef6ff;
          padding: 2px 8px;
          margin-right: 5px;
          border-radius: 8px;
          font-size: 10px;
          display: flex;
          align-items: center;
          color: #157AFE;
        }
      }
    }

    .details{
      background: #fff;
      padding: 10px;
    }

    .serveExplain,
    .chargeExplain{
      font-weight: bold;
      margin-top: 10px;
      background-color: #fff;
      .title{
        padding:5px 10px 10px;
      }
    }

    .tip{
      padding:15px;
      margin-bottom: 10px;
      background-color: #fff;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-all;
      .title{
        font-size: 14px;
        line-height: 22px;
      }
      .text{
        color: #6D6D6D;
        line-height: 18px;
        font-size: 12px;
      }
    }

    .mask{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0,0,0,0.5);
      z-index: 1000;
      height: 1400px;
    }

    .show_popup{
      position: sticky;
      bottom: -1px;
      background: #fff;
      width: 100%;
      padding: 12px 15px;
      z-index: 2000;
      border-radius: 15px 15px 0 0;
      .show_head{
        display: flex;
        padding-bottom: 15px;
        .head_content{
          margin: 5px 0 5px 10px;
          line-height: 1.2rem;
          white-space: pre-wrap;
          word-break: break-all;
          flex: 1;
          font-size: 14px;
          .name{
            font-size: 12px;
            font-weight: bold;
            line-height: normal;
          }
          .detail{
            font-size: 12px;
            height: fit-content;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .time,
          .num{
            font-size: 12px;
            color: #6D6D6D;
          }
          .price{
            color: #FF3C5F;
            text{
              font-size: 16px;
            }
          }
        }
      }
      .specify_name{
        .specName{
          font-weight: bold;
        }
        .specify_info{
          font-size: 12px;
          margin: 15px 0;
          padding: 0 15px;
          height: 30px;
          background: #F3F3F3;
          border-radius: 22px;
          border: 1px solid #F3F3F3;
          cursor: pointer;
          .info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            .info_name {
              display: flex;
              flex: 1;
              align-items: center;
              .name{
                color: #3D3D3D;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .strikethrough {
              font-size: 12px;
              padding: 0 15px;
              color: #999999;
              text-decoration-line: line-through;
              span{
                font-size: 16px;
              }
            }

            .price{
              color: #FF3C5F;
              text{
                font-size: 16px;
              }
            }
          }

        }

        .active{
          border: 1px solid #3D7FFF;
          background: rgba(61, 127, 255, 0.1);
          .info{
            .name{
              color: #3D7FFF;
            }
          }
        }
      }

      .specify_time,
      .specify_num{
        .title{
          font-weight: bold;
        }
        .time_box{
          display: flex;
          height: 30px;
          font-size: 12px;
          margin: 15px 0;
          .time{
            background: #F3F3F3;
            line-height: 30px;
            padding: 0 15px;
            margin-right: 10px;
            border-radius: 15px;
            cursor: pointer;
          }
          .active{
            color: #3D7FFF;
            border: 1px solid #3D7FFF;
            background: rgba(61, 127, 255, 0.1);
            line-height: 28px;
          }
        }
        .specify_tip {
          font-size: 10px;
          color: #6D6D6D;
          margin-bottom: 15px;
        }

      }
      .specify_time {
        .time_box {
          margin: 15px 0 12px;
        }
      }
      .show_foot{
        background: #3d7fff;
        text-align: center;
        color: #fff;
        line-height: 30px;
        border-radius: 20px;
        cursor: pointer;
      }

    }

    .show_site,
    .show_notice,
    .show_tip{
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 230px;
      background: #fff;
      padding: 12px 15px;
      z-index: 2000;
      border-radius: 15px;
      font-size: 12px;
      .title{
        font-weight: bold;
        text-align: center;
        font-size: 14px;
      }
      .site,
      .unavailable_time{
        border-bottom: 1px solid #E4E4E4;
        line-height: 20px;
        padding: 15px 0;
        white-space: break-spaces;
        .value{
          color: #9D9D9D;
          font-size: 10px;
        }

      }
      .ch_button{
        width: 100%;
        border-radius: 20px;
        transform: translateX(-5%);
        margin-top: 10px;
      }
    }

    .show_tip {
      .tip_list{
        .tip_item{
          border-radius: 4px;
          background: #F3F3F3;
          font-size: 12px;
          margin: 6px 0;
          padding: 8px 10px;
        }
      }
    }
  }

  .watch_content{
    .mainImageUrl{
      width: 100%;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
::v-deep .w-e-image-container {
  img {
    width: 330px;
    display: block;
  }
}

</style>