<template>
  <div>
    <video src="@/assets/mp3/serveOrder.mp3" style="display: none" ref="serveOrderVideo" ></video>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {Notification} from "element-ui";
import router from "@/route/router"
export default {
  name: 'AdminSocket',
  inject:['reload'],
  sockets: {
    HmNoticeMsg(value) {
      if(value){
        Notification({
          title: value.type,
          message:value.message,
          dangerouslyUseHTMLString: true,
          duration: 0,
          onClick: () => {
            if(value.code === 1) {
              router.push({name: 'orders'})
              this.saveFlush(true)
            }
            else if(value.code === 2) {
              router.push({name: 'serviceOrders'})
              this.saveFlush(true)
            }
            else if(value.code === 3) {
              router.push({name: 'userAppeal'})
              this.saveFlush(true)
            }
          }
        });
        if(value.code === 2 && value.message.includes('待接单')){
          this.$refs.serveOrderVideo.play()
        }
        this.$curl.get("/hm/homePage/getNoticeList").then((res) => {
          this.saveNoticeMsg(res.data)
        })
      }
    },
  },
  async mounted() {
    if (this.$socket.connected) return
    await this.closeSocketio()
    await this.conentSocketio()
  },
  beforeDestroy() {
    this.closeSocketio()
  },
  computed:{
    // ...mapGetters(['communityInfo'])
  },
  methods: {
    handleRender() {
      this.reload()  // 刷新页面
    },
    ...mapMutations({
      saveNoticeMsg: 'payment/saveNoticeMsg',
      saveFlush: 'saveFlush'
    }),
    async conentSocketio() {
        const token = localStorage.getItem("token")
        if (!token)  return
        const uri = process.env.VUE_APP_CONNECTION_URL + "/?token=" + token;
        this.$socket.io.uri = uri
        this.$socket.open();
        // this.sendMsg();
        // this.sockets.subscribe('message', (res) => {
        //   console.log('新写法获取回调', res)
        // })
    },
    sendMsg() {
      this.$socket.emit("message", JSON.stringify({userName: "cxf", passWord: "123456"}));
    },
    async closeSocketio() {
        console.log('关闭链接socket')
        this.$socket.close();
    },
  },
}
</script>

<style lang="scss">
.el-notification__group{
  word-break: break-all
}
.el-notification__content{
  text-align: start;
  strong{
    font-weight: 600;
  }
}
</style>
