<template>
  <el-dialog  :title="title"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="500px" @open="openModal"
              @closed="closeModal">
    <ch-form
        :render-option="option"
        :model="modalForm"
        ref="form"
        :rules="rules"
        :props="{ labelWidth: '120px', paddingRight: '0px' }"
    ></ch-form>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok"  @click="handleModal" :loading="loading">确定</ch-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  props:{
    getListFunction:{
      type: Function
    }
  },
  data(){
    return {
      isOpenModal:false,
      loading:false,
      title: '编辑自定义编号',
      modalForm: {},
      rules:{
        customNumber: [{ required: true, message: '自定义编号', trigger: ['blur', 'change'] }]
      }
    }
  },
  computed: {
    option() {
      return [
        {type: 'input', label: '自定义编号', prop: 'customNumber', placeholder: '最多只能输入10个字',props:{showWordLimit:true,maxlength: 10}},
      ]
    }
  },
  methods:{
    openModal() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    openEdit(row){
      this.isOpenModal = true
      this.modalForm = JSON.parse(JSON.stringify(row));
    },
    handleModal() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$curl.put('/hm/goods/editCustomNumber', this.modalForm).then(() => {
            this.$message.success('编辑成功')
            this.closeModal()
            this.getListFunction()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    closeModal(){
      this.isOpenModal = false
      this.modalForm = {}
    }
  }
}
</script>
<style scoped lang="scss">

</style>